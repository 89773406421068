import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

export const BASE_URI = process.env.GRAPHQL_API_URL;

export const ACCESS_TOKEN_KEY = "access_token";
let apolloClient;

export const httpLink = createHttpLink({
    uri: BASE_URI, // Server URL (must be absolute)
    // TODO: config CORS from server
    // credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
});

export const getAuthLink = () =>
    setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    });

function createApolloClient() {
    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        link: getAuthLink().concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        // allPosts: concatPagination(),
                    },
                },
            },
        }),
    });
}

export function updateApollo() {}

export function initializeApollo(initialState = null) {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

export function addApolloState(client, pageProps) {
    if (pageProps?.props) {
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}
