export const data = {
    popupMsgType: {
        success: "success",
        processing: "processing",
        fail: "fail",
        confirm: "confirm",
    },
    customContentType: {
        vestingSchedule: "vestingSchedule"
    }
}