import ListSocial from "@/base/components/elements/list-social";
import NavBar from "@/base/components/modules/navbar";
import { data as listSocialData } from "@/base/shared/list-social";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { data } from "@/shared/navbar";

const Layout = (props) => {
    const router = useRouter();

    let activeIndex = 0;
    data.items.forEach((item, index) => {
        if (item.link == router.pathname.toLowerCase()) {
            activeIndex = index;
        }
    });

    return (
        <>
            <Head>
                <title>MetaDino | MarketPlace</title>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
                />
            </Head>
            <ListSocial data={listSocialData} />
            <NavBar activeIndex={activeIndex} />
            <main id="main" className="flex flex-col overflow-hidden">
                <div>{props.children}</div>
            </main>
        </>
    );
};

export default Layout;
