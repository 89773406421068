export const data = {
    logo: {
        img: "/img/metadino-logo@3x.webp",
        alt: "",
        width: 270,
        height: 201,
    },
    profileIcon: {
        img: "/img/profile-icon@3x.webp",
        alt: "",
        width: 54,
        height: 53,
    },
    hamburgerIcon: {
        img: "/img/hamburger-icon@3x.webp",
        width: 37,
        height: 31,
    },
    closeBurgerIcon: {
        img: "/img/close-burger-icon@3x.webp",
        width: 37,
        height: 31,
    },
    items: [
        {
            title: "Seed",
            link: "/seed",
            target: "_self",
        },
        {
            title: "Private",
            link: "/private-sale",
            target: "_blank",
        },
        {
            title: "Dino Egg",
            link: "/dino-egg",
            target: "_blank",
        },
        {
            title: "Staking",
            link: "/staking",
            target: "_blank",
        },
        {
            title: "My Allocation",
            link: "/my-allocation",
            target: "_blank",
            requireAuth: true,
        },
    ],
};
