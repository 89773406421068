import { gql } from "@apollo/client";
export const LOGIN_MUTATION = gql`
    mutation LoginCustomer($address: String!) {
        loginCustomer(address: $address)
    }
`;

export const REGISTER_MUTATION = gql`
    mutation RegisterCustomer($address: String!) {
        registerCustomer(address: $address)
    }
`;

export const CUSTOMER_DETAIL_QUERY = gql`
    query Customer {
        customer {
            _id
            email
            address
            whitelists {
                _id
                customerAddress
                isWinner
                isPurchased
                isContributed
                buyAmount
            }
            vestingSchedules {
                _id
                vestingAt
                amount
                ratingPercent
                isVested
            }
        }
    }
`;
