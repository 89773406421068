// =========
// Global
export const INIT_APP_STATE = "INIT_APP_STATE";

// Init config
export const initAppAction = (payload) => {
    console.log("initAppAction", payload);
    return {
        type: INIT_APP_STATE,
        payload: payload,
    };
};

// =========
// User
export const CONNECT_WALLET = "CONNECT_WALLET";
export const DISCONNECT_WALLET = "DISCONNECT_WALLET";

export const CHANGE_AUTH_STATUS = "CHANGE_AUTH_STATUS";
export const UPDATE_USER = "UPDATE_USER";

export const INIT_ALLOCATION = "INIT_ALLOCATION";
export const UPDATE_VESTING = "UPDATE_VESTING";

// Add todo
export const connectWalletAction = (etherInfo) => {
    return {
        type: CONNECT_WALLET,
        payload: {
            ether: etherInfo,
        },
    };
};

export const updateUserAction = (payload) => {
    return {
        type: UPDATE_USER,
        payload: payload,
    };
};

export const initAlloctionAction = ({ allocations }) => {
    return {
        type: INIT_ALLOCATION,
        payload: {
            allocations,
        },
    };
};

export const updateVestingAction = ({ saleId, vestingSchedule }) => {
    return {
        type: UPDATE_VESTING,
        payload: {
            saleId,
            vestingSchedule,
        },
    };
};
