import Image from "next/image";
import Link from "next/link";

const HamburgerMenu = ({ data, activeIndex, handleClick }) => {
    return (
        <div className="flex flex-row justify-between rounded-xl bg-hamburgerMenu/[.35] backdrop-blur-xl w-[88%] px-4 py-5">
            <div className="">
                <Image
                    src={data.profileIcon.img}
                    width={data.profileIcon.width}
                    height={data.profileIcon.height}
                    layout="intrinsic"
                />
            </div>
            <div className="w-full space-y-5 flex flex-col items-end justify-end">
                {data.items.map((item, index) => {
                    return (
                        <div
                            className="space-y-2 text-right"
                            key={index.toString()}
                        >
                            <Link href={item.link}>
                                <a
                                    target={item.target}
                                    className="text-white hover:text-maize"
                                >
                                    <span className="uppercase">
                                        {item.title}
                                    </span>
                                </a>
                            </Link>
                            <div
                                className={
                                    index == activeIndex
                                        ? "border-solid border-b-4 shadow-lg w-full shadow-activeMenuItem shadow-lightMossGreen border-lightMossGreen"
                                        : "hidden"
                                }
                                key={index.toString()}
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HamburgerMenu;
