export const minifyAddress = (address, prefixLength = 5, subfixLength = 3) => {
    let firstStr = address.substring(0, prefixLength);
    let lastStr = address.substring(address.length - subfixLength);
    return firstStr + "..." + lastStr;
};

export const formatBalance = (num) => {
    return Math.round(num * 1e4) / 1e4;
};

export const isDevelopment = process.env.NODE_ENV === "development";
export const isTest = process.env.NODE_ENV === "test";
export const isProduction = process.env.NODE_ENV === "production";
