import { ethers, Contract } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import seedInstoSC from "@/lib/abi/seed-insto.json";
import vestingSC from "@/lib/abi/vesting.json";
import busdSC from "@/lib/abi/busd.json";

// Note: can di vao chinh thuc gameplay cho phat trien.

// TODO: get address from BE
const contractAddress = {
    // Test 2
    seed: "0x91432A89Aa5181460bb59bFbC7aECEaE051CB6b6",
    vesting: "0x7a88d2e2eda131856052abd22b1da2f9eca44e82",
    mdn: "0x509753abfb77f90c57cf8e937f8246cfb09e83dd",
    acceptedToken: "0xb34cade658b9b07800962557941ce9184dfed9cd",
    // onw busd:
    busd: "0xb34cade658b9b07800962557941ce9184dfed9cd",
    // TODO: check network
    validNetworkVersion: 97, // testnet
    chainId: "0x61", // testnet
};

// TODO: replace by BE config
const getContractAddress = () => {
    if (process.env) {
        return {
            seed: process.env.SEED_SC_ADDRESS,
            vesting: process.env.VESTING_SC_ADDRESS,
            mdn: process.env.MDN_SC_ADDRESS,
            acceptedToken: process.env.PAYABLE_SC_ADDRESS,
            validNetworkVersion: process.env.VALID_NETWORK_VER,
            chainId: process.env.CHAIN_ID,
        };
    }
    return contractAddress;
};

export const getBlockchain = () =>
    new Promise(async (resolve, reject) => {
        const provider = await detectEthereumProvider();
        if (provider) {
            try {
                const configContractAddress = getContractAddress();
                console.log("getBlockchain", { configContractAddress });

                // TODO: confirm connect on page load or not
                // provider.request({ method: "eth_requestAccounts" });

                const networkVersion = await provider.request({ method: "net_version" });

                // TODO: confirm need to sign or not
                // await provider.request({
                //     method: "eth_sign",
                //     params: [ethereum.selectedAddress, "hello world but a lil bit longer"],
                // });

                const ethProvider = new ethers.providers.Web3Provider(provider);
                const signer = ethProvider.getSigner();

                if (networkVersion !== configContractAddress.validNetworkVersion) {
                    console.log("getBlockchain");
                    const hasSwitched = await switchNetwork(
                        provider,
                        configContractAddress.chainId
                    );
                }

                const seedContract = new Contract(
                    // Contract address
                    configContractAddress.seed,
                    seedInstoSC.abi,
                    signer
                );

                const vestingContract = new Contract(
                    // Contract address
                    configContractAddress.vesting,
                    vestingSC.abi,
                    signer
                );

                // Accepted token Contract
                let payableContract;
                if (configContractAddress.acceptedToken) {
                    payableContract = new Contract(
                        // Contract address
                        configContractAddress.acceptedToken,
                        busdSC.abi,
                        signer
                    );
                }
                console.log("getBlockchain", {
                    ethProvider,
                    signer,
                    networkVersion,
                    seedContract,
                    vestingContract,
                    payableContract,
                });

                resolve({
                    ethProvider,
                    signer,
                    networkVersion,
                    seedContract,
                    vestingContract,
                    payableContract,
                });
                return;
            } catch (error) {
                console.log("getBlockchain_error", error);
                // await provider.request({ method: "personal_sign" });
            }
        }
        reject("Please install MetaMask!");
    });

export const switchNetwork = async (provider, chainId) => {
    try {
        console.log("switchNetwork", chainId);
        await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }],
        });
        return true;
    } catch (switchError) {
        console.log("switchNetwork_error", switchError);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
                await provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: chainId,
                            chainName: "BSC Testnet",
                            rpcUrls: [
                                "https://data-seed-prebsc-1-s1.binance.org:8545",
                            ] /* ... */,
                        },
                    ],
                });
            } catch (addError) {
                // handle "add" error
                console.log("switchNetwork_error", addError);
            }
        }
        return false;
        // handle other "switch" errors
    }
};

export const getSeedData = async (saleContract) => {
    try {
        if (saleContract) {
            const seedData = await saleContract.seedData();
            console.log("getSeedData", { seedData });
            // 0: balance
            // 1: hardCap
            // 2: seedPackage
            // 3: estReceiveMDN
            // 4: isWhitelisted
            // 5: isPurchased
            // 6: limitWhitelist: for all users
            return seedData;
        }
    } catch (error) {
        console.log("getSeedData_error", error);
    }
    return false;
};

export const getVestingData = async (vestingContract) => {
    try {
        if (vestingContract) {
            const vestingData = await vestingContract.vestingInfo();
            console.log("getVestingData", { vestingData });
            return vestingData;
        }
    } catch (error) {
        console.log("getVestingData_error", error);
    }
    return false;
};

export const getClaimableAmount = async (vestingContract) => {
    try {
        if (vestingContract) {
            const claimableAmount = await vestingContract.calVestAmount();
            console.log("getClaimableAmount", { claimableAmount });
            return claimableAmount;
        }
    } catch (error) {
        console.log("getClaimableAmount_error", error);
    }
    return false;
};
