import Image from "next/image";
import Link from "next/link";
import { useContext, useState } from "react";
import { data } from "@/shared/navbar";
import ConnectWallet from "../elements/connect-wallet";
import HamburgerMenu from "../elements/hamburger-menu";
import AppContext from "@/contexts/appctx/app-context";

const NavBar = ({ activeIndex = 0 }) => {
    const { user } = useContext(AppContext);
    const [toggleHambuger, setToggleHamburger] = useState(false);

    const handleBurgerClick = () => {
        setToggleHamburger(!toggleHambuger);
    };

    const renderBurgerIcon = () => {
        if (toggleHambuger) {
            return (
                <button onClick={handleBurgerClick}>
                    <div className=" flex items-center">
                        <Image
                            src={data.closeBurgerIcon.img}
                            width={data.closeBurgerIcon.width}
                            height={data.closeBurgerIcon.height}
                            layout="intrinsic"
                        />
                    </div>
                </button>
            );
        } else {
            return (
                <button onClick={handleBurgerClick}>
                    <div className=" flex items-center">
                        <Image
                            src={data.hamburgerIcon.img}
                            width={data.hamburgerIcon.width}
                            height={data.hamburgerIcon.height}
                            layout="intrinsic"
                        />
                    </div>
                </button>
            );
        }
    };
    const renderHamburgerMenu = () => {
        if (toggleHambuger) {
            return (
                <div className="absolute w-full flex items-center justify-center">
                    <HamburgerMenu data={data} activeIndex={activeIndex} />
                </div>
            );
        }
    };
    return (
        <div className="absolute inset-x-0 top-[2%] z-50">
            <nav className="hidden md:block lg:container mx-auto w-full px-[1%] lg:px-[3%]">
                <div className="flex flex-row justify-around items-center w-full h-1/5">
                    <div className="flex flex-row h-full">
                        <div className="uppercase flex flex-row justify-start items-center md:space-x-[1%] lg:space-x-[2%] xl:space-x-[5%]">
                            <div className="w-1/5 shrink-0">
                                <Link href="/">
                                    <Image
                                        src={data.logo.img}
                                        alt={data.logo.alt}
                                        width={data.logo.width}
                                        height={data.logo.height}
                                        layout="intrinsic"
                                    />
                                </Link>
                            </div>
                            <div className="hidden sm:flex flex-nowrap whitespace-nowrap font-primary text-sm md:text-xs lg:text-xl space-x-[5%] w-full">
                                {data.items.map((item, index) => {
                                    if (item.requireAuth) {
                                        if (
                                            !user ||
                                            !user?.isAuthenticated ||
                                            !user?.isConnected
                                        ) {
                                            return <span key={index.toString()}></span>;
                                        }
                                    }
                                    return (
                                        <div className="space-y-2" key={index.toString()}>
                                            <Link href={item.link}>
                                                <a
                                                    target={item.target}
                                                    className={
                                                        index == activeIndex
                                                            ? "text-white hover:text-maize text-sm md:text-xs lg:text-xl"
                                                            : "text-white hover:text-maize text-xs md:text-sm lg:text-lg"
                                                    }
                                                >
                                                    <span>{item.title}</span>
                                                </a>
                                            </Link>
                                            <div
                                                className={
                                                    index == activeIndex
                                                        ? "border-solid border-b-4 shadow-lg shadow-activeMenuItem shadow-lightMossGreen border-lightMossGreen"
                                                        : "border-solid border-b-4 shadow-lg shadow-white/20 border-white/20"
                                                }
                                            ></div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <ConnectWallet />
                    </div>
                </div>
            </nav>
            <div className="block md:hidden">
                <div className="flex flex-row justify-between items-center px-[6%] space-x-[2%]">
                    <div className="shrink-0 w-1/5 flex items-center">
                        <Link href="/">
                            <Image
                                src={data.logo.img}
                                alt={data.logo.alt}
                                width={data.logo.width}
                                height={data.logo.height}
                                layout="intrinsic"
                            />
                        </Link>
                    </div>
                    <div className="flex flex-row items-center justify-center space-x-5">
                        <ConnectWallet />
                        {renderBurgerIcon()}
                    </div>
                </div>
                {renderHamburgerMenu()}
            </div>
        </div>
    );
};

export default NavBar;
