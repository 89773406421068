import { data as popupConfig } from "@/shared/popup-msg";
import Image from "next/image";
import CustomButton from "./custom-button";
import { useContext } from "react";
import messageContext from "@/contexts/message-handler/message-context";

const PopupMsg = () => {
    const msgContext = useContext(messageContext);
    const { isOpen, isLoading, messageData: data, closeCallback, okCallback } = msgContext;

    const closePopupMsg = closeCallback ? closeCallback : () => {};
    const handleCustomBtn = okCallback ? okCallback : () => {};

    console.log("PopupMsg", {
        isOpen,
        isLoading,
        data,
        closeCallback,
        okCallback,
    });
    if (data && isOpen) {
        const renderClosePopupIcon = () => {
            if (!isLoading) {
                return (
                    <div
                        className={
                            data.isSpecialBg
                                ? "absolute top-[13%] md:top-[21%] lg:top-[31%] right-[17%] md:right-[24%] lg:right-[33%]"
                                : "absolute top-[5%] right-[5%]"
                        }
                    >
                        <button onClick={() => closePopupMsg()}>
                            <svg
                                className="w-[15px] h-[15px]"
                                viewBox="0 0 15 15"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    stroke="#FFF"
                                    strokeWidth="2"
                                    fill="none"
                                    fillRule="evenodd"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="m1 1 13 13M14 1 1 14" />
                                </g>
                            </svg>
                        </button>
                    </div>
                );
            }
            return null;
        };
        const renderPopupIcon = () => {
            if (data.popupMsgType) {
                switch (data.popupMsgType) {
                    case popupConfig.popupMsgType.success:
                        return (
                            <div>
                                <svg
                                    className="w-[27px] md:w-[40px] h-[27px] md:h-[40px]"
                                    viewBox="0 0 46 46"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill="none" fillRule="evenodd">
                                        <circle fill="#A7DC68" cx="23" cy="23" r="23" />
                                        <path
                                            stroke="#FFF"
                                            strokeWidth="5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13 21.695 20.447 30 33 16"
                                        />
                                    </g>
                                </svg>
                            </div>
                        );
                        break;
                    case popupConfig.popupMsgType.fail:
                        return (
                            <div>
                                <svg
                                    className="w-[27px] md:w-[40px] h-[27px] md:h-[40px]"
                                    viewBox="0 0 27 27"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill="none" fillRule="evenodd">
                                        <circle fill="#F73737" cx="13.5" cy="13.5" r="13.5" />
                                        <g
                                            stroke="#FFF"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        >
                                            <path d="m9 9 9.188 9.188M18.188 9 9 18.188" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        );
                        break;
                    case popupConfig.popupMsgType.confirm:
                        return (
                            <div>
                                <svg
                                    className="w-[27px] md:w-[40px] h-[27px] md:h-[40px]"
                                    viewBox="0 0 46 46"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill="none" fill-rule="evenodd">
                                        <circle fill="#A7DC68" cx="23" cy="23" r="23" />
                                        <path
                                            stroke="#FFF"
                                            strokeWidth="5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13 21.695 20.447 30 33 16"
                                        />
                                    </g>
                                </svg>
                            </div>
                        );
                        break;
                    default:
                        return null;
                }
            }
            return null;
        };
        const renderTitle = () => {
            if (data.title) {
                return (
                    <div className="flex items-center justify-center text-center">
                        <span className="uppercase text-sm md:text-lg font-primary w-[80%] min-w-[200px]">
                            {data.title}
                        </span>
                    </div>
                );
            }
            return null;
        };
        const renderDesc = () => {
            if (data.desc) {
                return (
                    <div className="flex items-center justify-center text-center">
                        <span className="text-lg md:text-2xl font-primary">{data.desc}</span>
                    </div>
                );
            }
            return null;
        };
        const renderSubDesc = () => {
            if (data.subDesc) {
                return (
                    <div className="flex items-center justify-center text-center">
                        <span className="text-lg md:text-2xl font-primary">{data.subDesc}</span>
                    </div>
                );
            }
            return null;
        };
        const renderImage = () => {
            if (data.image) {
                return (
                    <div className="w-[25%] md:w-[50%] lg:w-auto flex justify-center">
                        <Image
                            src={data.image.img}
                            width={data.image.width}
                            height={data.image.height}
                            layout="intrinsic"
                        />
                    </div>
                );
            }
            return null;
        };
        const renderLoading = () => {
            if (isLoading) {
                return (
                    <div className="w-full flex items-centers justify-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            viewBox="0 0 200 200"
                            fill="none"
                            color="#a7dc68"
                            className="animate-spin"
                        >
                            <defs>
                                <linearGradient id="spinner-secondHalf">
                                    <stop
                                        offset="0%"
                                        stopOpacity="0"
                                        stopColor="currentColor"
                                    />
                                    <stop
                                        offset="100%"
                                        stopOpacity="0.5"
                                        stopColor="currentColor"
                                    />
                                </linearGradient>
                                <linearGradient id="spinner-firstHalf">
                                    <stop
                                        offset="0%"
                                        stopOpacity="1"
                                        stopColor="currentColor"
                                    />
                                    <stop
                                        offset="100%"
                                        stopOpacity="0.5"
                                        stopColor="currentColor"
                                    />
                                </linearGradient>
                            </defs>

                            <g strokeWidth="8">
                                <path
                                    stroke="url(#spinner-secondHalf)"
                                    d="M 4 100 A 96 96 0 0 1 196 100"
                                />
                                <path
                                    stroke="url(#spinner-firstHalf)"
                                    d="M 196 100 A 96 96 0 0 1 4 100"
                                />
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    d="M 4 100 A 96 96 0 0 1 4 98"
                                />
                            </g>
                        </svg>
                        {/*<Image src={"/img/loading.gif"} width={80} height={80} intrinsic />*/}
                    </div>
                );
            }
            return null;
        };
        const renderBscScanBtn = () => {
            if (data.bscScanBtn && !isLoading) {
                return (
                    <div>
                        <a
                            href={data.bscScanBtn.link}
                            target={data.bscScanBtn.target}
                            className="text-primary"
                        >
                            <CustomButton
                                disable={data.bscScanBtn.disable ?? false}
                                label={data.bscScanBtn.title}
                                handleClick={handleCustomBtn}
                            />
                        </a>
                    </div>
                );
            }
            return null;
        };
        const renderCustomBtn = () => {
            if (data.customBtn) {
                return (
                    <CustomButton
                        disable={data.customBtn.disable ?? false}
                        label={data.customBtn.title}
                        handleClick={!data.customBtn.disable ? handleCustomBtn : undefined}
                    />
                );
            }
            return null;
        };
        const renderMsg = () => {
            if (data.msg) {
                return (
                    <div className="w-[60%] md:w-[80%] leading-3 md:leading-loose flex items-center justify-center text-center">
                        <p className="text-xs md:text-sm lg:text-md text-center font-secondary">
                            {data.msg}
                        </p>
                    </div>
                );
            }
            return null;
        };
        const renderLayout = () => {
            if (data.customContent) {
                switch (data.customContent.contentType) {
                    case popupConfig.customContentType.vestingSchedule:
                        return (
                            <div
                                className="container mx-auto z-50 fixed top-[50%] translate-y-[-50%] backdrop-fitler backdrop-blur-lg shadow-popupMsg
                                            flex flex-col justify-center items-center text-primary rounded-2xl w-full md:min-w-md md:max-w-lg"
                            >
                                {renderClosePopupIcon()}
                                {data.customContent.renderContent(data.childrenData)}
                            </div>
                        );
                        break;
                    default:
                        return null;
                }
            } else {
                if (data.isSpecialBg) {
                    return (
                        <div
                            className={
                                "container mx-auto z-50 fixed top-[50%] translate-y-[-50%] flex justify-center items-center w-[283px] h-[449px] md:w-[566px] md:h-[898px] lg:w-[849px] lg:h-[1347px]"
                            }
                        >
                            <div className="bg-[url('/img/claimed-popup-bg@3x.webp')] bg-contain bg-center bg-no-repeat w-full h-full flex flex-col justify-center items-center text-primary font-primary md:min-w-sm md:max-w-md">
                                <div className="flex flex-col items-center justify-start space-y-4">
                                    {renderClosePopupIcon()}
                                    {renderPopupIcon()}
                                    <div className="flex flex-col space-y-0">
                                        {renderTitle()}
                                        {renderDesc()}
                                        {renderSubDesc()}
                                    </div>
                                    {renderLoading()}
                                    {renderImage()}
                                    {renderBscScanBtn()}
                                    {renderMsg()}
                                    {renderCustomBtn()}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="container mx-auto z-50 fixed top-[50%] translate-y-[-50%] flex flex-col justify-center items-center text-primary font-primary space-y-3 md:space-y-5
                                    bg-darkGray backdrop-fitler backdrop-blur-lg bg-popupMsg shadow-popupMsg rounded-2xl px-5 py-6 md:py-8 w-full md:min-w-xs md:max-w-sm"
                        >
                            {renderClosePopupIcon()}
                            {renderPopupIcon()}
                            <div className="flex flex-col space-y-2">
                                {renderTitle()}
                                {renderDesc()}
                                {renderSubDesc()}
                            </div>
                            {renderLoading()}
                            {renderImage()}
                            {renderBscScanBtn()}
                            {renderMsg()}
                            {renderCustomBtn()}
                        </div>
                    );
                }
            }
        };
        return (
            <div className="flex items-center justify-center">
                <div className="fixed top-0 left-0 w-full h-full z-40 flex items-center">
                    <div className="absolute w-full h-full bg-black/[.82]"></div>
                </div>
                {renderLayout()}
            </div>
        );
    }
    return <div></div>;
};

export default PopupMsg;
