import * as actions from "./app-actions";

const appReducer = (state, action) => {
    switch (action.type) {
        // Global
        case actions.INIT_APP_STATE:
            console.log(action.payload);
            return {
                ...state,
                blockchain: { ...action.payload },
                isReady: true,
            };

        // User
        case actions.CONNECT_WALLET:
            return {
                ...state,
                user: {
                    ...state.user,
                    ether: action.payload.ether,
                    // Check connect wallet or not
                    isConnected: action.payload.ether?.address != undefined,
                },
            };

        case actions.DISCONNECT_WALLET:
            return {
                ...state,
                user: {
                    ...state.user,
                    ether: {},
                    isConnected: false,
                },
            };

        case actions.CHANGE_AUTH_STATUS:
            return {
                ...state,
                user: {
                    ...state.user,
                    accessToken: action.payload.accessToken,
                    isAuthenticated: action.payload.isAuthenticated,
                },
            };

        case actions.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                    // Remove to prevent override: bug
                    // isWinner: action.payload.isWinner,
                    // isPurchased: action.payload.isPurchased,
                },
            };

        case actions.INIT_ALLOCATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    allocations: action.payload.allocations,
                },
            };

        case actions.UPDATE_VESTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    allocations: state.allocations.map((allo) =>
                        allo.saleId === action.payload.saleId
                            ? {
                                  ...allo,
                                  vestingSchedule: action.payload.vestingSchedule,
                              }
                            : allo
                    ),
                },
            };
        default:
            return state;
    }
};

export default appReducer;
