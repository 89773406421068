export const data = [
    {
        name: "Facebook",
        link: "https://www.facebook.com/",
        icon: (
            <svg width="14" height="20" viewBox="0 0 14 25" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.473.005 10.115 0c-3.773 0-6.21 2.415-6.21 6.153v2.836H.527A.52.52 0 0 0 0 9.5v4.11c0 .282.237.51.528.51h3.376V24.49c0 .282.237.51.528.51h4.405a.52.52 0 0 0 .528-.51V14.12h3.948a.519.519 0 0 0 .528-.51l.002-4.11a.502.502 0 0 0-.155-.361.538.538 0 0 0-.374-.15H9.365V6.585c0-1.156.286-1.743 1.845-1.743h2.262a.52.52 0 0 0 .528-.51V.514a.52.52 0 0 0-.527-.51z" fill="#FFF" fillRule="nonzero"/>
            </svg>
        ),
    },
    {
      name: "Twitter",
      link: "https://twitter.com/home",
      icon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2203 3.39947C15.451 3.33052 15.6451 3.59405 15.4864 3.77507C15.216 4.08351 14.9191 4.3659 14.5959 4.62227C14.4478 4.73973 14.3562 4.916 14.3562 5.10502V5.21817C14.3562 6.29835 14.1553 7.37851 13.7534 8.45868C13.3272 9.56286 12.7306 10.547 11.9635 11.4111C11.1355 12.3353 10.1613 13.0554 9.0411 13.5715C7.81126 14.1236 6.47794 14.3996 5.0411 14.3996C4.12785 14.3996 3.23897 14.2736 2.37443 14.0216C1.78653 13.8536 1.22248 13.6298 0.682273 13.3502C0.506764 13.2593 0.587762 12.9954 0.785388 12.9954C1.54034 12.9954 2.26484 12.8754 2.9589 12.6353C2.99601 12.6225 3.03295 12.6094 3.06971 12.596C3.70993 12.3629 3.50678 11.3751 2.9589 10.9701C2.56849 10.6815 2.26385 10.336 2.045 9.93369C1.91397 9.69283 2.13676 9.43083 2.41096 9.43083C2.62056 9.43083 2.63832 9.13717 2.45114 9.04286C2.27231 8.95276 2.10062 8.84505 1.93607 8.71972C1.53424 8.41367 1.2207 8.03562 0.995434 7.58554C0.770166 7.13547 0.651446 6.6584 0.639269 6.15432C0.639269 6.13025 0.665299 6.11511 0.686399 6.1267C0.825293 6.20299 0.967443 6.26804 1.11285 6.32186C1.39827 6.42751 1.55798 6.04769 1.36978 5.80851C1.25834 5.66689 1.15791 5.51512 1.06849 5.3532C0.806696 4.87912 0.675799 4.36604 0.675799 3.81396C0.675799 2.98955 1.67822 2.86707 2.31017 3.39648C2.85282 3.85107 3.44653 4.24227 4.09132 4.57007C5.05261 5.05847 6.0663 5.37197 7.13243 5.51058C7.50534 5.55906 7.79909 5.21616 7.79909 4.84012C7.79909 4.25202 7.9452 3.71194 8.23744 3.21986C8.52968 2.72778 8.92846 2.33473 9.43379 2.04068C9.93912 1.74663 10.484 1.59961 11.0685 1.59961C11.5312 1.59961 11.9696 1.68962 12.3836 1.86965C12.6761 1.99687 12.9413 2.16005 13.1792 2.35919C13.3645 2.51436 13.6059 2.6006 13.8401 2.54082C14.057 2.48546 14.2695 2.41939 14.4777 2.34261C14.8657 2.19951 15.2477 2.54832 14.9863 2.86881C14.9781 2.87884 14.9699 2.88881 14.9616 2.89873C14.7958 3.09748 14.9723 3.4736 15.2203 3.39947Z"
            fill="#ffffff"
          ></path>
        </svg>
      ),
    },
    {
      name: "Telegram",
      link: "https://web.telegram.org/",
      icon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.4705 2.0787C15.4584 2.02328 15.4319 1.97207 15.3935 1.93029C15.3552 1.88852 15.3064 1.85768 15.2522 1.84095C15.0553 1.80169 14.8514 1.81611 14.662 1.8827C14.662 1.8827 1.57347 6.69545 0.768225 7.12995C0.581975 7.22995 0.552475 7.30795 0.525725 7.3847C0.395725 7.7572 0.800725 7.92145 0.800725 7.92145L4.18747 9.02445L5.80472 13.4204C5.80472 13.4204 5.98197 13.8467 6.23122 13.8467C6.33747 13.8467 6.57847 13.7349 6.92222 13.3909C7.65247 12.6607 8.35322 12.0554 8.70297 11.7614C9.86797 12.5657 11.1212 13.4549 11.6622 13.9204C11.753 14.0083 11.8605 14.077 11.9783 14.1225C12.0962 14.1679 12.222 14.1892 12.3482 14.1849C12.862 14.1657 13.0052 13.6017 13.0052 13.6017C13.0052 13.6017 15.4002 3.96245 15.4802 2.67095C15.488 2.54445 15.4987 2.46395 15.5 2.37695C15.5038 2.27665 15.4939 2.1763 15.4705 2.0787ZM4.76247 8.76045C6.45497 7.69245 12.1522 4.1042 12.515 3.97095C12.5787 3.95195 12.6255 3.9737 12.613 4.01745C12.4517 4.5832 6.40947 9.9547 6.38797 9.97395C6.37393 9.98701 6.36279 10.0029 6.3553 10.0206C6.3478 10.0382 6.34411 10.0573 6.34447 10.0764L6.11097 12.4907L4.76247 8.76045Z"
            fill="#ffffff"
          ></path>
        </svg>
      ),
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/",
      icon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.85 4.8C15.85 4.8 15.7 3.7 15.225 3.2C14.625 2.575 13.925 2.55 13.625 2.525C11.35 2.375 8 2.375 8 2.375C8 2.375 4.65 2.375 2.4 2.525C2.075 2.55 1.4 2.575 0.8 3.2C0.325 3.675 0.175 4.8 0.175 4.8C0.175 4.8 0 6.1 0 7.4V8.625C0 9.925 0.15 11.225 0.15 11.225C0.15 11.225 0.3 12.325 0.775 12.825C1.375 13.45 2.175 13.45 2.55 13.5C3.825 13.625 8 13.65 8 13.65C8 13.65 11.35 13.65 13.6 13.475C13.925 13.45 14.6 13.425 15.2 12.8C15.675 12.325 15.825 11.2 15.825 11.2C15.825 11.2 16 9.9 16 8.6V7.375C16 6.1 15.85 4.8 15.85 4.8ZM6.35 10.075V5.575L10.675 7.825L6.35 10.075Z"
            fill="#ffffff"
          ></path>
        </svg>
      ),
    },
];