const ListSocial = ({data}) => {
    const SocialIconButton = (props) => (
        <>
          <a href={props.link ?? "/"} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        </>
    );
    if (data) {
        return (
          <div className="hidden md:block fixed z-50 top-[50%] translate-y-[-50%] right-6 cursor-pointer ">
            <div className="bg-black/[.6] rounded-3xl flex flex-col  space-y-5 items-center py-4 px-3">
              {data.map((e, index) => (
                <SocialIconButton link={e.link} key={index}>
                  <div className="hover:scale-150">{e.icon}</div>
                </SocialIconButton>
              ))}
            </div>
          </div>
        )
    }
    return null
};
  
export default ListSocial;
  