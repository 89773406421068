import PopupMsg from "@/components/elements/popup-msg";
import Layout from "@/components/layouts/Layout";
import AppState from "@/contexts/appctx/app-state";
import MessageState from "@/contexts/message-handler/message-state";
import { useApollo } from "@/hooks/my-hook";
import { ApolloProvider } from "@apollo/client";
import AOS from "aos";
import { useEffect } from "react";
import "../styles/globals.css";


// TODO: Muting error, fix as soon as zeit/next.js/issues/7915 resolved
const originalError = console.error;

console.error = (...args) => {
    if (/Warning.*Function components cannot be given refs/.test(args[0])) {
        return;
    }
    originalError.call(console, ...args);
};

function MyApp({ Component, pageProps }) {
    useEffect(() => {
        AOS.init({
            easing: "ease-in-sine",
            once: false,
            mirror: true,
            offset: 150,
        });
    }, []);

    const apolloClient = useApollo(pageProps);

    const handleCustomBtn = () => {
        alert("You have clicked on custom btn");
    };

    return (
        <ApolloProvider client={apolloClient}>
            <AppState>
                <Layout>
                    <MessageState>
                        <Component {...pageProps} />
                        <PopupMsg />
                    </MessageState>
                </Layout>
            </AppState>
        </ApolloProvider>
    );
}

export default MyApp;
