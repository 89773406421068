export const CustomBtnBgColor = {
    type1: "lightMossGreen",
    type2: "darkBlue"
}

const CustomButton = ({disable, label, handleClick, bgColor}) => {
    return (
        <button onClick={() => handleClick ? handleClick() : () =>{} }>
            <div className={
                disable ?
                "flex items-center justify-center rounded-lg bg-darkGray px-8 py-2" :
                bgColor == CustomBtnBgColor.type1 ?
                "flex items-center justify-center rounded-lg bg-lightMossGreen px-8 py-2" :
                bgColor == CustomBtnBgColor.type2 ?
                "flex items-center justify-center rounded-lg bg-darkBlue px-8 py-2" :
                "flex items-center justify-center rounded-lg bg-lightMossGreen px-8 py-2" 
            }>
                <span className={
                    disable ?
                    "uppercase text-xs md:text-sm text-primary font-secondary font-medium text-black/[.3]" :
                    "uppercase text-xs md:text-sm text-primary font-secondary font-medium"
                }>{label}</span>
            </div>
        </button>
    )
}

export default CustomButton;
