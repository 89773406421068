import AppContext from "@/contexts/appctx/app-context";
import { formatBalance, minifyAddress } from "@/utils/common-utils";
import { getBlockchain } from "@/utils/blockchain";

import { ethers } from "ethers";
import Image from "next/image";
import { useContext, useEffect, useState } from "react";

/**
 * Seed round:
 * - hardcap: $600,000
 * - price per slot: $10,000
 * - price: $0.015
 * - rate: (BNB/USD): $400
 * - rate (BNB/MDN): 1 BNB = 26666 MDN
 * - alloction per slot: 666,666 MDN
 * -
 *
 * BSUD Testnet:
 * Addr: 0x8301f2213c0eed49a7e28ae4c3e91722919b8b47
 *
 * MDNT smart contract:
 * Addr: 0x509753abfb77f90c57cf8e937f8246cfb09e83dd
 * Trx: https://testnet.bscscan.com/tx/0xd696e74817c78b91d864fbaf4ff94e1db488416816d6eb9972bedce1162a66c3
 *
 * Seed sale smart contract:
 * Test 1:
 *      Addr: 0x3cabf8b418d7df816d4bb5ce2fd8086a8c404198
 *
 * Vesting smart contract:
 * Addr: 0x7a88d2e2eda131856052abd22b1da2f9eca44e82
 * Trx: https://testnet.bscscan.com/tx/0x98ed68566614b2b59b5626ee3bf63acf37a43149072eabca81b6ce45bc4f8462
 *
 */

const ConnectWallet = () => {
    const appContext = useContext(AppContext);

    const [userWallet, setUserWallet] = useState({});

    // console.log("ConnectWallet", { appContext });

    useEffect(() => {
        const initWallet = async () => {
            // console.log("initWallet", { appContext });
            if (appContext?.isReady) {
                const user = appContext.user;
                setUserWallet({
                    address: user.ether.address,
                    balance: user.ether.balance,
                    currency: user.ether.currency,
                });
            }
        };

        initWallet();
    }, [appContext?.user]);

    const data = {
        title: "Connect Wallet",
        walletInfo: {
            // address: "0x713563a8F783b993758d94e5E735bEc3Ae2CBD92",
            currency: {
                img: "/img/busd-logo.png",
                width: 24,
                height: 24,
            },
            // balance: "1.034"
        },
    };

    /**
     * connect wallet extension
     */
    const connectWalletExtension = async () => {
        console.log("connectWalletExtension", appContext.blockchain);
        if (appContext.blockchain) {
            if (!userWallet.address) {
                appContext.blockchain.ethProvider.provider.request({
                    method: "eth_requestAccounts",
                });
            } else {
                // View account on ether scan
                const blockExplorerURL = process.env.BLOCK_EXPLORER_URL;
                window
                    .open(blockExplorerURL + "address/" + userWallet.address, "_blank")
                    .focus();
            }
        } else {
            alert("Please install MetaMask!");
        }
    };

    // console.log("balance", userWallet?.balance);

    const renderLayout = () => {
        let formattedBalance = userWallet?.balance || 0;
        formattedBalance = Math.round(formattedBalance * 1e4) / 1e4;
        if (data.walletInfo) {
            return (
                <div className="flex flex-row items-center text-primary space-x-2 text-xs md:text-md shrink-0 overflow-hidden">
                    <div className="shrink-0 flex items-center">
                        <Image
                            src={data.walletInfo.currency.img}
                            width={data.walletInfo.currency.width}
                            height={data.walletInfo.currency.height}
                            layout="intrinsic"
                        />
                    </div>
                    <span className="">
                        {userWallet?.balance
                            ? `${formatBalance(userWallet?.balance).toLocaleString("en")} ${
                                  userWallet?.currency
                              }`
                            : ""}
                    </span>
                    <button
                        onClick={connectWalletExtension}
                        className="rounded-lg bg-lightMossGreen flex flex-row items-center justify-center px-2 py-1"
                    >
                        {userWallet.address ? (
                            <>
                                <span className="">{minifyAddress(userWallet.address)}</span>
                            </>
                        ) : (
                            <>
                                <span className="">Connect Wallet</span>
                            </>
                        )}
                    </button>
                </div>
            );
        } else {
            return (
                <div>
                    <span className="lowercase text-lg text-primary font-primary whitespace-nowrap hidden lg:block">
                        {data.title}
                    </span>
                    <svg
                        width="22"
                        height="26"
                        viewBox="0 0 22 26"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <path id="ymoxbrc6ya" d="M0 0h20v16H0z" />
                            <path id="nqfckyz3yc" d="M0 0h7v7H0z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(0 10)">
                                <path
                                    d="M.918 16C.186 15.683 0 15.39 0 14.552V1.287C0 .625.333.157.897.03 1.007.003 1.125 0 1.24 0h17.505c.796 0 1.243.466 1.245 1.3.003.829.001 1.658.001 2.52h-.282c-2.076 0-4.152-.003-6.228.002-1.274.004-2.354.866-2.462 2.166-.11 1.334-.11 2.693.002 4.027.11 1.3 1.193 2.158 2.466 2.16 2.068.005 4.136.002 6.204.002h.3v.28c0 .685-.023 1.37.006 2.054.032.733-.216 1.247-.901 1.488H.918z"
                                    fill="#FFF"
                                    mask="url(#vyoq21fp4b)"
                                />
                            </g>
                            <path
                                d="M16.487 18.492c-.004-.613-.485-1.099-1.08-1.091-.583.007-1.051.495-1.052 1.098-.001.61.479 1.105 1.07 1.103.592-.002 1.067-.498 1.062-1.11m1.01 2.507H14.07c-.718 0-1.07-.36-1.07-1.09l.001-2.888c.001-.624.368-1.016.98-1.017 2.345-.005 4.69-.005 7.035 0 .608.001.98.396.982 1.02.003.977.002 1.955 0 2.933 0 .653-.37 1.038-1.006 1.04-1.165.005-2.33.002-3.495.002"
                                fill="#FFF"
                            />
                            <g transform="translate(12)">
                                <path
                                    d="M3.492 7A3.49 3.49 0 0 1 0 3.474 3.497 3.497 0 0 1 3.522 0 3.495 3.495 0 0 1 7 3.516 3.489 3.489 0 0 1 3.492 7"
                                    fill="#FFF"
                                    mask="url(#kaimxsb7bd)"
                                />
                            </g>
                            <path
                                d="M4.057 8c-.261-.875.398-1.984 1.504-2.552 1.182-.606 2.746-.597 3.921.024 1.079.57 1.725 1.681 1.458 2.528H4.057z"
                                fill="#FFF"
                            />
                        </g>
                    </svg>
                </div>
            );
        }
    };
    return (
        <div className="border-solid border-2 rounded-xl shadow-connectWallet flex flex-row items-center justify-center px-2 py-1 md:py-2 lg:space-x-4">
            {renderLayout()}
        </div>
    );
};

export default ConnectWallet;
