import { ACCESS_TOKEN_KEY, getAuthLink, httpLink, initializeApollo } from "@/lib/apollo";
import {
    CUSTOMER_DETAIL_QUERY,
    LOGIN_MUTATION,
    REGISTER_MUTATION,
} from "@/lib/graphql/customer";
import { getBlockchain } from "@/utils/blockchain";
import { ethers } from "ethers";
import { useEffect, useReducer } from "react";
import {
    connectWalletAction,
    initAlloctionAction,
    initAppAction,
    updateUserAction,
    updateVestingAction,
} from "./app-actions";
import AppContext from "./app-context";
import appReducer from "./app-reducer";

const initialState = {
    isReady: false,
};

/**
 * Save Customer & Blockchain Info
 * @param {*} props
 * @returns
 */
const AppState = (props) => {
    const [state, dispatch] = useReducer(appReducer, initialState);
    const apolloClient = initializeApollo();
    useEffect(() => {
        initBlockchain();

        window.ethereum &&
            window.ethereum.on("accountsChanged", function (accounts) {
                console.log(accounts);
                if (state.user) {
                    initBlockchain();
                } else {
                    window.location.reload();
                }
            });

        window.ethereum &&
            window.ethereum.on("chainChanged", function (accounts) {
                window.location.reload();
            });

        // componentWillUnmount
        return () => {
            if (window.ethereum?.off && typeof window.ethereum?.off == "function") {
                window.ethereum?.off("accountsChanged");
                window.ethereum?.off("chainChanged");
            }
        };
    }, []);

    const initBlockchain = async () => {
        const {
            signer,
            ethProvider,
            seedContract,
            vestingContract,
            payableContract,
            networkVersion,
        } = await getBlockchain();
        console.log("initBlockchain");

        let balance = 0,
            signerAddress;
        // Try to get balance
        try {
            signerAddress = await signer.getAddress();

            // Get BNB balance
            balance = await ethProvider.getBalance(signerAddress);

            // OR Get BUSD balance
            if (payableContract) {
                balance = await payableContract.balanceOf(signerAddress);
            }

            if (signerAddress) {
                initUserInfo(signerAddress);
            }
        } catch (error) {
            console.log("initBlockchain_error", error);
        }
        // Init appContext.blockchain HERE
        dispatch(
            initAppAction({
                ethProvider,
                signer,
                seedContract,
                vestingContract,
                payableContract,
                networkVersion,
            })
        );

        dispatch(
            connectWalletAction({
                address: signerAddress,
                balance: ethers.utils.formatEther(balance),
                network: networkVersion,
                currency: payableContract ? "BUSD" : "BNB",
            })
        );
    };

    const doLogin = async (signerAddress) => {
        try {
            const loginResp = await apolloClient.mutate({
                mutation: LOGIN_MUTATION,
                variables: { address: signerAddress },
                notifyOnNetworkStatusChange: true,
            });
            console.log("doLogin", loginResp);

            if (loginResp.data.loginCustomer) {
                localStorage.setItem(ACCESS_TOKEN_KEY, loginResp.data.loginCustomer);
                apolloClient.setLink(getAuthLink().concat(httpLink));
                console.log("doLogin", apolloClient);
            }
            return loginResp.data.loginCustomer;
        } catch (error) {
            console.log("doLogin_error", error.message);
            // TODO: API message changed
            // if (error?.message == "CUSTOMER_NOT_EXIST") {
            doRegister(signerAddress);
            // }
        }
    };

    const doRegister = async (signerAddress) => {
        try {
            // const [mutateFunction, { data, loading, error }] = useMutation(INCREMENT_COUNTER);
            const registerResp = await apolloClient.mutate({
                mutation: REGISTER_MUTATION,
                variables: { address: signerAddress },
                notifyOnNetworkStatusChange: true,
            });
            console.log("doRegister", registerResp);

            if (registerResp.data.registerCustomer) {
                localStorage.setItem(ACCESS_TOKEN_KEY, registerResp.data.registerCustomer);
                apolloClient.setLink(getAuthLink().concat(httpLink));
                console.log("doRegister", apolloClient);
            }
        } catch (error) {
            console.log("doRegister_error", error);
        }
    };

    const initUserInfo = async (signerAddress) => {
        try {
            const accessToken = await doLogin(signerAddress);
            // TODO: test -> remove bellow
            // GRAPHQL_API_URL=https://api.metadino.me/api/dinosaur-world
            // const accessToken = "abc";
            if (accessToken) {
                // getCustomer
                const cusomerDetailResp = await apolloClient.query({
                    query: CUSTOMER_DETAIL_QUERY,
                    variables: {},
                    notifyOnNetworkStatusChange: true,
                });
                console.log("initUserInfo", cusomerDetailResp);
                if (cusomerDetailResp?.data?.customer) {
                    const userInfo = cusomerDetailResp?.data?.customer;
                    dispatch(
                        updateUserAction({
                            userId: userInfo._id,
                            accessToken: accessToken,
                            email: userInfo.email,
                            isAuthenticated: true,
                            // isWinner: false,
                            // isPurchased: false,
                        })
                    );
                }
                // TODO: test -> remove bellow
                // else {
                //     dispatch(
                //         updateUserAction({
                //             userId: "",
                //             accessToken: accessToken,
                //             email: "",
                //             isAuthenticated: true,
                //         })
                //     );
                // }
            }
        } catch (e) {
            console.log("initUserInfo_error", e);
        }
    };

    console.log("AppState", { state });

    // Combine state & actions
    const mapStateToContext = (state) => ({
        isReady: state.isReady,
        blockchain: state.blockchain,
        user: state.user,
    });

    const mapDispatchToContext = (dispatch) => ({
        connectWallet: (etherInfo) => dispatch(connectWalletAction(etherInfo)),
        updateUser: (userInfo) => dispatch(updateUserAction(userInfo)),
        initAlloction: ({ allocations }) => dispatch(initAlloctionAction({ allocations })),
        updateVesting: ({ saleId, vestingSchedule }) =>
            dispatch(updateVestingAction({ saleId, vestingSchedule })),
    });

    return (
        <AppContext.Provider
            value={{
                ...mapStateToContext(state),
                ...mapDispatchToContext(dispatch),
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppState;
