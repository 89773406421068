import React, { useReducer } from "react";
import MessageContext from "./message-context";

const OPEN_MESSAGE_POPUP = "SHOW_MESSAGE_POPUP";
const UPDATE_MESSAGE_POPUP = "UPDATE_MESSAGE_POPUP";
const CLOSE_MESSAGE_POPUP = "CLOSE_MESSAGE_POPUP";

const messageReducer = (state, action) => {
    console.log();
    switch (action.type) {
        case OPEN_MESSAGE_POPUP:
            return {
                ...state,
                ...action.payload,
                isOpen: true,
            };
        case UPDATE_MESSAGE_POPUP:
            return {
                ...state,
                ...action.payload,
            };
        case CLOSE_MESSAGE_POPUP:
            return {
                ...state,
                ...action.payload,
                isOpen: false,
                isLoading: false,
            };
        default:
            return state;
    }
};

const MessageState = (props) => {
    const initialState = {
        isOpen: false,
        isLoading: false,
        messageData: undefined,
        closeCallback: undefined,
        okCallback: undefined,
    };
    const [state, dispatch] = useReducer(messageReducer, initialState);

    // Switch new tab
    const openAction = (payload) => {
        return {
            type: OPEN_MESSAGE_POPUP,
            payload: payload,
        };
    };

    const updateAction = (payload) => {
        return {
            type: UPDATE_MESSAGE_POPUP,
            payload: payload,
        };
    };

    const closeAction = () => {
        return {
            type: CLOSE_MESSAGE_POPUP,
            payload: {},
        };
    };

    // Combine state & actions
    const mapStateToContext = (state) => ({
        isOpen: state.isOpen,
        isLoading: state.isLoading,
        messageData: state.messageData,
        closeCallback: state.closeCallback,
        okCallback: state.okCallback,
    });

    const mapDispatchToContext = (dispatch) => ({
        openPopup: (payload) => dispatch(openAction(payload)),
        updatePopupData: (payload) => dispatch(updateAction(payload)),
        closePopup: () => dispatch(closeAction()),
    });

    console.log("MessageState", state);

    return (
        <MessageContext.Provider
            value={{
                ...mapStateToContext(state),
                ...mapDispatchToContext(dispatch),
            }}
        >
            {props.children}
        </MessageContext.Provider>
    );
};

export default MessageState;
